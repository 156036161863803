<script setup lang="ts">
import RadioInputGroup from "@/components/shared/forms/radio/RadioInputGroup.vue";
import RadioInputComponent from "@/components/shared/forms/radio/RadioInputComponent.vue";
import DropDownComponent from "@/brands/bit1111/components/shared/forms/dropdown/DropDownComponent.vue";
import { ref, watch, computed, provide, inject, toRef } from "vue";
import { useLoaderStore } from "@/stores/loader";
import AdditionalDataWrapper from "@/brands/bit1111/components/deposit/additionalData/AdditionalDataWrapper.vue";
import { useBalanceStore } from "@/stores/balance";
import type { BalanceKey } from "@/stores/balance";
import { translate } from "@/services/translations/translation";
import { useLayoutModalsStore } from "@/stores/layoutModals";
import { getPaymentMethods } from "@/services/payment/payment";
import type { Deposit } from "@/services/payment/depositAbstract/deposit";
import { generateDepositObject } from "@/services/payment/depositImplementations/depositFactory";
import type { HandlerResponse } from "@/services/payment/depositAbstract/handleDeposit";
import ButtonComponent from "@/brands/bit1111/components/shared/buttons/ButtonComponent.vue";
import InputValueComponent from "@/brands/bit1111/components/shared/forms/input/InputValueComponent.vue";
// import { usePaymentStore } from "@/stores/payments";
import { usePaymentStore } from "@/stores/payments";
import WarningComponent from "@/brands/bit1111/components/bonus/WarningComponent.vue";
import axios from "axios";
import FormWarningComponent from "@/brands/bit1111/components/shared/forms/FormWarningComponent.vue";
import { computeFormValidation } from "@/services/validation/formValidation";
import { useBonusesStore } from "@/stores/bonuses";

const balanceStore = useBalanceStore();
const balance = computed(() =>
  balanceStore.getBalance(selectedWalletName.value)
);
const balanceWallet = computed(() =>
  balanceStore.getWallet(selectedWalletName.value)
);
const bonusesStore = useBonusesStore();
const finishedLoading = ref(false);
const selectedWallet = ref();
const selectedWalletName = ref<BalanceKey>("casino");
const layoutModalsStore = useLayoutModalsStore();
const bonuses = ref();
const providers = ref();
const wallets = ref();
const currency = ref("");
const pricePoints = ref([10, 25, 100, 200]);
const bonusCodeFlag = ref(false);
const bonusCode = ref("");
const selectedBonus = ref();
const selectedBonusNum = ref(-1);
const selectedPricePoint = ref();
const depositAmount = ref();
const selectedPiQ = ref();
const loader = useLoaderStore();
const walletsTranslations = ref<Array<any>>([]);
const deposit = ref<Deposit>();
const handlerResponse = ref<HandlerResponse | null>();
// const paymentStore = usePaymentStore();
const paymentStore = usePaymentStore();
const isAnyBonusActive = ref(false);

const back = () => {
  handlerResponse.value = null;
};

const loadWalletData = async (walletType: number) => {
  loader.toggleLoader(true);
  selectedPricePoint.value = null;
  depositAmount.value = null;
  bonuses.value = null;
  // const response = await getPaymentMethods("deposit", walletType);
  // let response = paymentStore.getMethod(walletType, "deposit") as any;
  // if (response == null) {
  //   await paymentStore.loadMethodsWait();
  //   response = paymentStore.getMethod(walletType, "deposit") as any;
  // }
  const response = await paymentStore.getMethodLoad("deposit", walletType);

  if (response) {
    const loadedBonuses = response.bonuses;
    bonuses.value = [];
    for (const bonus in loadedBonuses) {
      bonuses.value.push(loadedBonuses[bonus]);
    }
    await bonusesStore.loadAllBonuses();
    bonuses.value.forEach((element: any) => {
      element.object = bonusesStore.getBonusById(element.bonusId)
    });
    bonuses.value.push({
      title: translate("transactions.deposit.desktop.generals.withoutBonus"),
      object:{
        bonusAward:{
          bonus:{
            name:'I dont want a bonus'
          }
        }
      },
      bonusId: null,
    });
    console.log("bonuses: ",bonuses.value);
    
    providers.value = response.methods;
    wallets.value = response.wallet.options;
    selectedWallet.value = response.wallet.current;
    currency.value = response.currency.code;
    if (response.amounts > 0) {
      pricePoints.value = response.amounts;
    }
  }
  loader.toggleLoader(false);
};

const submit = async () => {
  loader.toggleLoader(true);
  let key: ValidationKey;
  for (key in validationList.value) {
    validationList.value[key].isTouched = true;
  }
  if (formisValid.value) {
    localStorage.setItem("depositWallet", selectedWalletName.value);
    localStorage.setItem("balance", balance.value as any);

    deposit.value = generateDepositObject({
      supplier: providers.value[selectedPiQ.value].supplier,
      provider: providers.value[selectedPiQ.value].provider,
      amount: parseFloat(depositAmount.value),
      walletType: selectedWallet.value,
      bonus_code: bonusCode.value,
      bonus_id: selectedBonus.value.bonusId,
    });

    handlerResponse.value = await deposit.value.handleDeposit();
  }
  loader.toggleLoader(false);
};

const clearRadioPrice = () => {
  if (depositAmount.value != selectedPricePoint.value) {
    selectedPricePoint.value = 0;
  }
};

const selectWallet = (wallet: number) => {
  selectedWallet.value = wallet;
  loadWalletData(selectedWallet.value);
};

const selectPricePoint = (price: number) => {
  selectedPricePoint.value = price;
  depositAmount.value = price.toString();
};

const setBonus = async (event: any,i:number) => {
  selectedBonus.value = event;
   selectedBonusNum.value =  i;
  validationList.value.bonus.valid = true;
  // if (selectedBonus.value.bonusId == 1) {
  //   bonusCodeFlag.value = true;
  // } else {
  //   bonusCodeFlag.value = false;
  //   bonusCode.value = "";
  // }

  if (selectedBonus.value.bonusId) {
    await axios
      .get("/obtained/bonus/active/check")
      .then((response) => {
        if (response.data.data.active) {
          isAnyBonusActive.value = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    isAnyBonusActive.value = false;
  }
};

watch(
  depositAmount,
  (value) => {
    clearRadioPrice();
  },
  { deep: true }
);
selectedWalletName;

watch(
  selectedWallet,
  (value) => {
    for (const key in wallets.value) {
      if (wallets.value[key].id == selectedWallet.value) {
        selectedWalletName.value = wallets.value[key].name.toLowerCase();
      }
    }
  },
  { deep: true }
);

const test = ref("MODAL IS CLOSED");
const handleClose = () => {
  layoutModalsStore.closeModal("depositModal");
};
const selectedMethod = (i: number) => {
  selectedPiQ.value = i;
  callbackValid("method", true);
};
const init = async () => {
  // finishedLoading.value = true;
  walletsTranslations.value = [];
  walletsTranslations.value.push(translate("common.casino"));
  walletsTranslations.value.push(translate("common.sportsbook"));
  await loadWalletData(1);
  finishedLoading.value = true;
};
init();

// VALIDATION START
const validationList = ref({
  bonus: {
    valid: false,
    isTouched: false,
    message: translate("transactions.deposit.desktop.generals.bonus_error"),
  },
  amount: {
    valid: false,
    isTouched: false,
    message: translate("transactions.withdrawal.desktop.valid_amount"),
  },
  method: {
    valid: false,
    isTouched: false,
    message: translate("transactions.deposit.desktop.generals.method_error"),
  },
});
type ValidationKey = keyof typeof validationList.value;

const formisValid = ref<boolean>(false);
const callbackValid = (pointer: ValidationKey, value: boolean) => {
  validationList.value[pointer].valid = value;
  computeFormValidation(validationList.value, formisValid);
};
// VALIDATION END

const divClassSelected =
  "border-input border-2-3 radius-20 square-40 txt-center bg-2-3 mgx-1";

const pClassSelected = "txt-4-1";
const divClassEmpty =
  "border-input border-2-3 radius-20 square-40 txt-center bg-5-1 mgx-1";

const pClassEmpty = "txt-2-3";
</script>

<template>
  <div class="backdrop scrollbar-style">
    <div
      v-if="finishedLoading"
      class="bg-4-6 deposit-container radius-15 background-img-personal"
    >
      <div class="flex flex-right">
        <span
          @click="handleClose"
          class="svg-icon mga-4 svg-icon block icon-16 icon-form-clear bg-1-1 cursor-pointer"
        ></span>
      </div>
      <div class="form-size">
        <h1 class="txt-center txt-1-1">
          {{
            translate(
              "transactions.deposit.desktop.sliderDepositText"
            ).toUpperCase()
          }}
        </h1>

        <div
          class="flex flex-align-center space-between mgy-6 subtitle-large txt-bold mgx-15"
        >
          <div
            class="flex flex-center"
            :class="
              !handlerResponse?.isAdditionalData
                ? divClassSelected
                : divClassEmpty
            "
          >
            <p
              :class="
                !handlerResponse?.isAdditionalData
                  ? pClassSelected
                  : pClassEmpty
              "
            >
              1
            </p>
          </div>
          <div class="h-2px w-60 bg-2-1"></div>
          <div
            class="flex flex-center"
            :class="
              handlerResponse?.isAdditionalData && deposit
                ? divClassSelected
                : divClassEmpty
            "
          >
            <p
              :class="
                handlerResponse?.isAdditionalData && deposit
                  ? pClassSelected
                  : pClassEmpty
              "
            >
              2
            </p>
          </div>
        </div>

        <form @submit.prevent v-if="!handlerResponse?.isAdditionalData">
          <div class="space-between flex pdy-2 pdx-6">
            <p class="title-smallest txt-bold">
              {{ translate("account.profile.desktop.available_balance") }}
            </p>
            <p id="balance" class="title-smallest txt-bold">
              {{
                balanceWallet.balance?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) +
                " " +
                currency
              }}
            </p>
          </div>
          <div class="space-between flex pdy-2 pdx-6">
            <p class="title-smallest txt-bold">
              {{ translate("account.profile.desktop.reserved_balance") }}
            </p>
            <p id="balance" class="title-smallest txt-bold">
              {{
                balanceWallet.reservedFunds?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) +
                " " +
                currency
              }}
            </p>
          </div>
          <div class="space-between flex pdt-2 pdb-4 pdx-6">
            <p class="title-smallest txt-bold">
              {{ translate("common.bonus") }}
            </p>
            <p id="balance" class="title-smallest txt-bold">
              {{
                balanceWallet.bonusBalance?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) +
                " " +
                currency
              }}
            </p>
          </div>
          <div class="ballance-wrapper-content pdx-6 mgb-4">
            <p class="mgt-3 wallet txt-left txt-light text-smallest">
              {{ translate("transactions.deposit.mobile.wallet_type") }}
            </p>
            <RadioInputGroup
              :class="'border-4-1 radius-right-12 radius-left-12  w-100 mgy-2 hidden-overflow border-width-1'"
            >
              <RadioInputComponent
                @click="selectWallet(wallet.id)"
                v-for="(wallet, i) of wallets"
                v-bind:key="i"
                :id="wallet.name.toLowerCase() + 'Wallet'"
                :wrapperClass="'radio-style-btn-wrapper w-50'"
                :labelText="walletsTranslations[i]"
                :classLabel="
                  wallet.id == selectedWallet
                    ? 'bg-1-1 flex flex-center btn-normal radio-style-btn'
                    : 'flex flex-center btn-normal radio-style-btn'
                "
                :disabled="i == 1 ? true : false"
              >
              </RadioInputComponent>
            </RadioInputGroup>
            <!-- <div class="mgt-6">
              <DropDownComponent
                dropdownId="bonus"
                :label="translate('transactions.deposit.mobile.select_bonus')"
                :options="bonuses"
                :selector="'title'"
                @emitSelected="setBonus"
                textCutBorder="label-cut-border-light"
                :borderClass="'border-input radius-10 '"
                v-model:isTouched="validationList.bonus.isTouched"
                @isValid="callbackValid('bonus', $event)"
              />
            </div> -->
     








            <WarningComponent v-if="isAnyBonusActive" />
            <!-- <div class="mgt-6">
              <InputValueComponent
                inputId="bonusCode"
                v-if="bonusCodeFlag"
                :placeholder="
                  translate('transactions.deposit.desktop.generals.bonusCode')
                "
                v-model="bonusCode"
                :inputType="'text'"
                textCutBorder="label-cut-border-light"
                :borderClass="'border-input radius-10 '"
              >
              </InputValueComponent>
            </div> -->
            <div class="mgt-6">
              <InputValueComponent
                inputId="amount"
                :placeholder="
                  translate('transactions.deposit.mobile.deposit_amount')
                "
                v-model="depositAmount"
                :regexValidator="'decimal'"
                textCutBorder="label-cut-border-light"
                :borderClass="'border-input radius-10 '"
                v-model:isTouched="validationList.amount.isTouched"
                @isValid="callbackValid('amount', $event)"
              >
              </InputValueComponent>
            </div>
            <div class="mgt-6">
              <RadioInputGroup
                id="amountOption"
                :class="'border-4-1 border-width-1 radius-5 w-100 mgy-4 hidden-overflow '"
              >
                <RadioInputComponent
                  @click="selectPricePoint(price)"
                  v-for="(price, i) of pricePoints"
                  v-bind:key="i"
                  :wrapperClass="'radio-style-btn-wrapper w-25'"
                  :labelText="price.toString()"
                  :classLabel="
                    selectedPricePoint == price
                      ? 'bg-brand flex flex-center btn-normal radio-style-btn'
                      : i == pricePoints.length - 1
                      ? 'no-border flex flex-center btn-normal radio-style-btn'
                      : 'flex flex-center btn-normal radio-style-btn'
                  "
                >
                </RadioInputComponent>
              </RadioInputGroup>
            </div>
                   <p class="wallet txt-left mgb-4 txt-light text-smallest">
              {{ 'Select offer' }}
            </p>
            <div
              class="mgb-4 flex deposit-providers flex-wrap fit-content mgx-auto"
            >
              <div
                :class="` txt-center  fit-content mgx-1`"
                v-for="(bonus, i) in bonuses"
                v-bind:key="i"
                :id="bonus.bonusId"
              >
                <div class="border-piq radius-6 h-65px w-119px mgt-2" :class="selectedBonusNum == i ? 'shadowbox' : ' '">
                  <!-- w-117px -->
                  <img
                    @click="setBonus(bonus,i)"
                    :src="bonus.object?.bonusAward?.bonus?.image"
                    :alt="bonus.object?.bonusAward?.bonus?.name"
                    class="cursor-pointer radius-6 h-65px w-119px"
                    
                  />    
                         <!--  --> 
                </div>
              </div>
            </div>
             <FormWarningComponent
              v-if="
                !validationList.bonus.valid && validationList.bonus.isTouched
              "
              :text="validationList.bonus.message"
            />


            <p class="wallet txt-left mgb-4 txt-light text-smallest mgt-4" >
              {{ translate("transactions.deposit.mobile.payment_methods") }}
            </p>
            <div
              class="mgb-4 flex deposit-providers flex-wrap fit-content mgx-auto"
            >
              <div
                :class="` txt-center  fit-content mgx-1`"
                v-for="(provider, i) in providers"
                v-bind:key="i"
                :id="provider.provider"
              >
                <div class="border-piq radius-6 h-65px w-119px mgt-2">
                  <!-- w-117px -->
                  <img
                    @click="selectedMethod(i)"
                    :src="provider.images.select.src"
                    :alt="provider.images.select.alt"
                    class="cursor-pointer radius-6 h-65px w-119px"
                    :class="selectedPiQ == i ? 'shadowbox' : ' '"
                  />
           
                </div>
              </div>
            </div>

            <FormWarningComponent
              v-if="handlerResponse?.isError"
              :text="handlerResponse?.responseMessage"
            />
            <FormWarningComponent
              v-if="
                !validationList.method.valid && validationList.method.isTouched
              "
              :text="validationList.method.message"
            />

            <span class="flex flex-center">
              <ButtonComponent
                class="mgy-4"
                id="submit"
                :widthPx="184"
                :heightPx="40"
                :text="translate('transactions.deposit.mobile.submit')"
                @click="submit"
              />
            </span>
          </div>
        </form>
        <AdditionalDataWrapper
          v-if="handlerResponse?.isAdditionalData && deposit"
          :deposit="deposit"
          @back="back"
        />
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped>
.shadowbox {
  box-shadow: 0px 0px 5px 5px #8fdb00;
  /* #a92779; */
}
.background-img-personal {
  background-image: url("https://i.ibb.co/kXjxDyH/bg-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>

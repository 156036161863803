import { bannersFallback } from "@/services/data/bannersFallback";
import { defineStore } from "pinia";
import type { BannerInterface } from "@/services/interface/banner";

export const useBannerStore = defineStore({
  id: "banner",
  state: () => ({
    banners: <Array<BannerInterface>>[],
  }),
  actions: {
    async getBanners(tag: string) {
      let filteredPayload = this.banners.filter((banner) => banner.tag == tag);
      if (filteredPayload.length == 0) {
        filteredPayload = bannersFallback.filter((banner) => banner.tag == tag);
      }
      return filteredPayload;
    },
    async getBannerById(id: number) {
      console.log("get by id: ", id);
      console.log("all banners: ",this.banners);
      
      let payload;
      this.banners.forEach((element:any) => {
        if(element.id == id) payload = element
      });
      return payload;
    },
    async setBanners(banners: Array<BannerInterface>) {
      this.banners = banners;
  },
}
});
